<template>
    <div>
      <ManageAssistantTeacherBar/>
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="กำหนดการรายงานตัวครูผู้ช้วย"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          กำหนดการ <span class="red--text">รายงานตัวครูผู้ช่วย</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                    <PeriodassteachChooseCollege/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
                  <v-timeline-item color="purple lighten-2" fill-dot right>
                    <v-card>
                      <v-card-title class="purple lighten-2">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                        วิทยาลัย รับรายงานตัวครูผู้ช่วย
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                        <periodassteachCollegeReport/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
            
  
  
            
                </v-timeline>
              </v-col>
            </v-row>
          </v-card>
        </base-material-card>
      </v-container>
    </div>
  </template>
  
  <script>
import ManageAssistantTeacherBar from '../../../components/admin/manageAssistantTeacherBar.vue';
import PeriodassteachChooseCollege from '../../../components/admin/periodassteachChooseCollege.vue';
import periodassteachCollegeReport from '../../../components/admin/periodassteachCollegeReport.vue';
  
  

  export default {
      name: "HRvecTransferTeachers",
      data() {
          return {
              loading: true,
              ApiKey: "HRvec2021",
          };
      },
      mounted() { },
      methods: {},
      components: { PeriodassteachChooseCollege, periodassteachCollegeReport, ManageAssistantTeacherBar }
  };
  </script>
  
  <style lang="scss" scoped></style>
  